'use client';

import { OverrideFeatureFlags } from '@components/override-feature-flags/override-feature-flags.module';
import { PREVIEW_QUERY_PARAMS } from '@config';
import type {
  PartialGlobalFeatureFlags,
  PartialInternalFeatureFlags,
} from '@contexts/feature-flags/feature-flags.types';
import {
  isValidSearchParamTriggerVal,
  isValidTestSearchParamTriggerVal,
} from '@utils/helpers/search-param-valid-trigger-val';
import { ProductVariantAvailability } from '@vcc-www/federated-graph-types';
import {
  AccordionDetails,
  AccordionSummary,
} from '@volvo-cars/react-accordion';
import { Checkbox, Select } from '@volvo-cars/react-forms';
import { useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';
import { PreviewModeModalCta } from './preview-mode-modal.cta';
import { parseOverrideAvailableAtValue } from './preview-mode-modal.utils';

export function PreviewModeModal() {
  // Feature flags logic
  const [updatedFeatureFlags, setUpdatedFeatureFlags] =
    useState<PartialInternalFeatureFlags>(null);
  const [updatedGlobalFeatureFlags, setUpdatedGlobalFeatureFlags] =
    useState<PartialGlobalFeatureFlags>(null);

  const [overrideFlagsQueryParam, setOverrideFlagsQueryParam] = useState<
    string | null
  >(null);

  // Existing "test" query params
  const searchParams = useSearchParams();
  const hasOverriddenFlags = searchParams.get(
    PREVIEW_QUERY_PARAMS.overrideFlags,
  );

  useEffect(() => {
    if (hasOverriddenFlags && !overrideFlagsQueryParam) {
      setOverrideFlagsQueryParam(`overrideFlags=${hasOverriddenFlags}`);
    }
  }, [hasOverriddenFlags, overrideFlagsQueryParam]);

  const isShowDictionariesTriggered = isValidSearchParamTriggerVal(
    searchParams.get(PREVIEW_QUERY_PARAMS.dictionaries),
  );
  const isShowSourcesTriggered = isValidSearchParamTriggerVal(
    searchParams.get(PREVIEW_QUERY_PARAMS.showSources),
  );
  const isErrorReportingTriggered = isValidSearchParamTriggerVal(
    searchParams.get(PREVIEW_QUERY_PARAMS.errorReporting),
  );
  const isUseDefaultMarketConfigTriggered = isValidSearchParamTriggerVal(
    searchParams.get(PREVIEW_QUERY_PARAMS.useDefaultMarketConfig),
  );
  const isUseProdDataTriggered = isValidSearchParamTriggerVal(
    searchParams.get(PREVIEW_QUERY_PARAMS.useProdData),
  );
  const isOverrideAvailableAtTriggered = isValidTestSearchParamTriggerVal(
    PREVIEW_QUERY_PARAMS.overrideAvailableAt,
  );

  // Show dictionaries and sources logic
  const [showDictionaries, setShowDictionaries] = useState(
    isShowDictionariesTriggered,
  );
  const [showSources, setShowSources] = useState(isShowSourcesTriggered);
  const [showErrorReporting, setShowErrorReporting] = useState(
    isErrorReportingTriggered,
  );
  const [useDefaultMarketConfig, setUseDefaultMarketConfig] = useState(
    isUseDefaultMarketConfigTriggered,
  );
  const [useProdData, setUseProdData] = useState(isUseProdDataTriggered);
  const [overrideAvailableAt, setOverrideAvaiableAt] =
    useState<ProductVariantAvailability | null>(
      isOverrideAvailableAtTriggered
        ? parseOverrideAvailableAtValue(
            searchParams.get(
              PREVIEW_QUERY_PARAMS.overrideAvailableAt,
            ) as ProductVariantAvailability,
          )
        : null,
    );

  return (
    <div>
      <AccordionDetails
        className="current:border-primary px-16 pb-16"
        defaultOpen
      >
        <AccordionSummary className="font-medium pt-0">
          Dictionaries, Sources and Error Reporting
        </AccordionSummary>
        <Checkbox
          label="Show dictionaries origin"
          name="checkbox"
          checked={showDictionaries}
          onChange={() => setShowDictionaries(!showDictionaries)}
        />
        <Checkbox
          label="Show query metadata"
          name="checkbox"
          checked={showSources}
          onChange={() => setShowSources(!showSources)}
        />
        <Checkbox
          label="Use default Market Config"
          name="checkbox"
          checked={useDefaultMarketConfig}
          onChange={() => setUseDefaultMarketConfig(!useDefaultMarketConfig)}
        />
        <Checkbox
          label="Use Production data"
          name="checkbox"
          checked={useProdData}
          onChange={() => setUseProdData(!useProdData)}
        />
        <Checkbox
          label="Show error reporting"
          name="checkbox"
          checked={showErrorReporting}
          onChange={() => setShowErrorReporting(!showErrorReporting)}
        />
        <Select
          className="mt-16"
          label="Set available at"
          name="Set available at"
          onChange={(e) => {
            return setOverrideAvaiableAt(
              parseOverrideAvailableAtValue(e.target.value),
            );
          }}
        >
          {Object.values(ProductVariantAvailability).map((key) => (
            <option key={key}>{key}</option>
          ))}
        </Select>
      </AccordionDetails>
      <OverrideFeatureFlags
        updatedFeatureFlags={updatedFeatureFlags}
        updatedGlobalFeatureFlags={updatedGlobalFeatureFlags}
        setOverrideFlagsQueryParam={setOverrideFlagsQueryParam}
        setUpdatedFeatureFlags={setUpdatedFeatureFlags}
        setUpdatedGlobalFeatureFlags={setUpdatedGlobalFeatureFlags}
      />

      <PreviewModeModalCta
        overrideAvailableAt={overrideAvailableAt}
        changeOverrideAvailableAt={
          overrideAvailableAt !==
          searchParams.get(PREVIEW_QUERY_PARAMS.overrideAvailableAt)
        }
        changeFeatureFlags={
          !!updatedFeatureFlags || !!updatedGlobalFeatureFlags
        }
        changeShowDictionaries={
          isShowDictionariesTriggered !== showDictionaries
        }
        changeShowErrorReporting={
          isErrorReportingTriggered !== showErrorReporting
        }
        changeShowSources={isShowSourcesTriggered !== showSources}
        overrideFlagsQueryParam={overrideFlagsQueryParam}
        showDictionaries={showDictionaries}
        showErrorReporting={showErrorReporting}
        showSources={showSources}
        useDefaultMarketConfig={useDefaultMarketConfig}
        useProdData={useProdData}
      />
    </div>
  );
}
