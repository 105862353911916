export interface LinksFilter {
  preload?: boolean;
  fonts?: boolean;
  tokens?: boolean;
}

export function createFilter({
  fonts = true,
  tokens = true,
}: LinksFilter = {}) {
  return ([name]: [string, string]) => {
    if (name === 'font-face.css') return fonts;
    if (name.endsWith('tokens.css')) return tokens;
    return true;
  };
}
