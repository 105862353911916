'use client';

import { PreviewMode } from '@components/preview-mode/preview-mode.module';
import { PREVIEW_QUERY_PARAMS } from '@config';
import type { ClientSideRuntimeVariables } from '@contexts/client-side-runtime-variables/client-side-runtime-variables.context';
import { DictionariesProvider } from '@contexts/dictionaries/dictionaries.provider';
import { FeatureFlagsProvider } from '@contexts/feature-flags/feature-flags.provider';
import type { FeatureFlags } from '@contexts/feature-flags/feature-flags.types';
import { GlobalErrorProvider } from '@contexts/global-error/global-error.provider';
import { GlobalLoadingProvider } from '@contexts/global-loading/global-loading.provider';
import { GlobalModalProvider } from '@contexts/global-modal/global-modal.provider';
import { isValidTestSearchParamTriggerVal } from '@utils/helpers/search-param-valid-trigger-val';
import { getPublicEnv } from '@utils/public-env';
import { initApm } from '@vcc-www/elastic-apm';
import { getApmVariablesFromPublicConfig } from '@vcc-www/elastic-apm/getApmVariablesFromPublicConfig';
import GlobalStyleProvider, {
  createRenderer,
} from '@vcc-www/global-style-provider';
import { SharedComponentsDictionariesProvider } from '@vcc-www/shared-dictionaries';
import { VolvoCarsUrlProvider, urlFromString } from '@vcc-www/volvo-cars-url';
import type { FlattenedDictionaries } from '@volvo-cars/content-delivery-client/dictionaries/types';
import { links } from '@volvo-cars/css/links';
import { type SiteSlug, getMarketSite } from '@volvo-cars/market-sites';
import { usePathname, useServerInsertedHTML } from 'next/navigation';
import { type ReactNode, useEffect, useState } from 'react';
import type { PageSearchParams } from 'src/types/page-search-params';
import { ClientSideRuntimeVariablesProvider } from '../../src/contexts/client-side-runtime-variables/client-side-runtime-variables.provider';

export const LayoutProviderWrapper = ({
  children,
  clientSideRuntimeVariables,
  dictionaries,
  featureFlags,
  searchParams,
  sharedDictionaries,
  siteSlug,
}: {
  children: ReactNode;
  clientSideRuntimeVariables: ClientSideRuntimeVariables;
  dictionaries: FlattenedDictionaries;
  featureFlags: FeatureFlags | null;
  searchParams: PageSearchParams;
  sharedDictionaries: FlattenedDictionaries;
  siteSlug: SiteSlug;
}) => {
  // Initialise the Elastic APM client
  initApm(getApmVariablesFromPublicConfig(getPublicEnv()));

  const pathname = usePathname();
  const [routeChangeHandler, setRouteChangeHandler] = useState<VoidFunction>();
  const { locale } = getMarketSite(siteSlug);

  // Update VolvoCarsUrl when the route changes. VolvoCarsUrl by default listens
  // to click events only which is too early some times
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (!routeChangeHandler) return;
    routeChangeHandler();
  }, [pathname, routeChangeHandler]);

  const view = createRenderer();

  useServerInsertedHTML(() => {
    return (
      <>
        {links().map((link) => (
          <link key={link.href} {...link} />
        ))}
      </>
    );
  });

  return (
    <ClientSideRuntimeVariablesProvider {...clientSideRuntimeVariables}>
      <GlobalStyleProvider renderer={view}>
        <VolvoCarsUrlProvider
          url={urlFromString(pathname || '')}
          setRouteChangeHandler={setRouteChangeHandler}
        >
          <FeatureFlagsProvider featureFlags={featureFlags}>
            <DictionariesProvider
              dictionaries={dictionaries}
              sharedDictionaries={sharedDictionaries}
              isDictionariesModeActive={isValidTestSearchParamTriggerVal(
                searchParams[PREVIEW_QUERY_PARAMS.dictionaries],
              )}
            >
              <SharedComponentsDictionariesProvider
                locale={locale}
                dictionaries={sharedDictionaries}
              >
                <GlobalModalProvider>
                  <PreviewMode
                    isPreviewModeActive={
                      isValidTestSearchParamTriggerVal(
                        searchParams[PREVIEW_QUERY_PARAMS.preview],
                      ) && clientSideRuntimeVariables.DEPLOY_ENV !== 'prod'
                    }
                  >
                    <GlobalErrorProvider>
                      <GlobalLoadingProvider>{children}</GlobalLoadingProvider>
                    </GlobalErrorProvider>
                  </PreviewMode>
                </GlobalModalProvider>
              </SharedComponentsDictionariesProvider>
            </DictionariesProvider>
          </FeatureFlagsProvider>
        </VolvoCarsUrlProvider>
      </GlobalStyleProvider>
    </ClientSideRuntimeVariablesProvider>
  );
};
