'use client';

import { createContext } from 'react';
import type { GlobalLoading } from './global-loading.types';

export const GlobalLoadingContext = createContext<GlobalLoading>({
  setGlobalLoading: () => {
    // Empty function placeholder
  },
  clearGlobalLoading: () => {
    // Empty function placeholder
  },
  hasGlobalLoading: () => false,
});
