import cssImports from './imports.json' assert { type: 'json' };
import { defaultBase } from './links/constants';
import { createFilter } from './links/create-filter';
import { getMediaQuery } from './links/get-media-query';
import type { LinkProps, LinksFunction } from './links/types';

export const links: LinksFunction = ({ base = defaultBase, filter } = {}) => {
  if (base && typeof base !== 'string') {
    base = base.href;
  }
  if (typeof base === 'string' && base !== '' && !base.endsWith('/')) {
    base += '/';
  }
  const linkProps = Object.entries(cssImports)
    .filter(createFilter(filter))
    .map(([name, filename]) => {
      const props = {
        rel: 'stylesheet',
        href: base + filename,
        'data-volvo-css-name': name,
        crossOrigin: 'anonymous',
      } as LinkProps;
      const media = getMediaQuery(name);
      if (media) {
        props.media = media;
      }
      return props;
    });
  linkProps.toString = function toString() {
    let html = '';
    for (const link of this) {
      const attrs = Object.entries(link)
        .map(([key, value]) => `${key}="${value}"`)
        .join(' ');
      html += `<link ${attrs} />\n`;
    }
    return html;
  };
  return linkProps;
};

export type { LinksFunction, LinkProps };
