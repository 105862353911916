import { DEPLOY_ENV } from '@config';
import type { FeatureFlags } from '@contexts/feature-flags/feature-flags.types';
import type {
  Flags,
  GlobalDotcomFlags,
} from '@vcc-www/feature-flags/src/flags.types';

function handleFlagToOverrideValue(val: any) {
  try {
    const objectVal = JSON.parse(val);
    return objectVal;
  } catch (error) {
    console.warn(
      `Value cannot be parsed in "handleFlagToOverrideValue" function: ${val}`,
    );
  }

  if (val === 'true') {
    return true;
  }

  if (val === 'false') {
    return false;
  }

  return val;
}

export function handleFeatureFlagsOverride({
  featureFlags,
  flagsToOverride,
}: {
  featureFlags: FeatureFlags;
  flagsToOverride: string | null;
}) {
  if (DEPLOY_ENV === 'prod') {
    return featureFlags;
  }

  if (!flagsToOverride) {
    return featureFlags;
  }

  if (!featureFlags?.featureFlags && !featureFlags?.globalFeatureFlags) {
    return featureFlags;
  }

  const parsedFlagsToOverride: Flags | GlobalDotcomFlags = {};
  const flagsToOverrideSeparated = flagsToOverride.split('.');

  flagsToOverrideSeparated.forEach((flag) => {
    const keyValueSplit = flag.split('-');

    if (keyValueSplit.length === 2) {
      parsedFlagsToOverride[keyValueSplit[0] as string] =
        handleFlagToOverrideValue(keyValueSplit[1]);
      return;
    }

    // When the flag value also contains "-"
    if (keyValueSplit.length > 2) {
      const valueJoined = keyValueSplit.slice(1).join('-');
      parsedFlagsToOverride[keyValueSplit[0] as string] =
        handleFlagToOverrideValue(valueJoined);
      return;
    }

    console.error(
      `Function "handleFeatureFlagsOverride": "keyValueSplit" is not separated into a key and value. Got: ${keyValueSplit}`,
    );
  });

  const featureFlagKeys = Object.keys(featureFlags).filter(Boolean);
  const parsedFlagsToOverrideKeys = Object.keys(parsedFlagsToOverride);
  const featureFlagValues = Object.values(featureFlags).filter(Boolean);

  const initialOverriddenFlags: FeatureFlags = {
    featureFlags: undefined,
    globalFeatureFlags: undefined,
  };
  const overriddenFlags = featureFlagKeys.reduce(
    (flagGroup: FeatureFlags, currentValue: string, currentIndex: number) => {
      const featureFlagsGroupValues: Flags = {
        ...featureFlagValues[currentIndex],
      };
      const featureFlagsGroupKeys = Object.keys(featureFlagsGroupValues);

      parsedFlagsToOverrideKeys.forEach((flagName) => {
        if (featureFlagsGroupKeys.includes(flagName)) {
          featureFlagsGroupValues[flagName] = parsedFlagsToOverride[flagName];
        }
      });

      return {
        ...flagGroup,
        [currentValue]: featureFlagsGroupValues,
      };
    },
    initialOverriddenFlags,
  );

  return overriddenFlags;
}
