'use client';

import type { BaseErrorInlineMessageType } from '@components/inline-message/inline-message.types';
import React, { useContext, useState } from 'react';
import { GlobalErrorContext } from './global-error.context';
import {
  type ErrorIds,
  type GlobalErrorProviderProps,
  errorDescriptions,
} from './global-error.types';

export function useGlobalError() {
  return useContext(GlobalErrorContext);
}

export function GlobalErrorProvider({ children }: GlobalErrorProviderProps) {
  const [errorIdList, setErrorIdList] = useState<ErrorIds[]>([]);

  function setGlobalError(errorIds: ErrorIds[]) {
    setErrorIdList((prevErrorIds) => {
      const newErrorIds = errorIds.filter(
        (errorId) => !prevErrorIds.includes(errorId),
      );
      return newErrorIds;
    });
  }

  function clearGlobalError() {
    setErrorIdList([]);
  }

  function hasGlobalError(errorIds: ErrorIds[]) {
    let hasError = false;
    let dictionaryItem: BaseErrorInlineMessageType = {
      title: '',
      description: '',
      navigationTitle: '',
    };

    for (const errorId of errorIds) {
      if (errorIdList.includes(errorId)) {
        hasError = true;
        dictionaryItem = getErrorDictionaryItem(errorId);
      }
    }

    return { hasError, dictionaryItem };
  }

  function getErrorDictionaryItem(errorId: ErrorIds) {
    return errorDescriptions[errorId];
  }

  return (
    <GlobalErrorContext.Provider
      value={{ setGlobalError, clearGlobalError, hasGlobalError }}
    >
      {children}
    </GlobalErrorContext.Provider>
  );
}
