import {
  type ClientSideRuntimeVariables,
  ClientSideRuntimeVariablesContext,
} from '@contexts/client-side-runtime-variables/client-side-runtime-variables.context';
import { type PropsWithChildren, useContext } from 'react';

export function ClientSideRuntimeVariablesProvider({
  children,
  BASE_URL,
  DEPLOY_ENV,
}: PropsWithChildren<ClientSideRuntimeVariables>) {
  return (
    <ClientSideRuntimeVariablesContext.Provider
      value={{ BASE_URL, DEPLOY_ENV }}
    >
      {children}
    </ClientSideRuntimeVariablesContext.Provider>
  );
}

export function useClientSideRuntimeVariables() {
  return useContext(ClientSideRuntimeVariablesContext);
}
